<template>
  <Row id="PaySuccess" align="middle">
    <Col class="header" :span="24">
      <span>支付成功</span>
      <Button type="primary" @click="closeWindow">關閉頁面</Button>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "PaySuccess",
}
</script>
<script setup>
import { Row, Col, Button } from "ant-design-vue";

function closeWindow() {
  // console.log("关闭浏览器");
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("MSIE") > 0) {
    if (userAgent.indexOf("MSIE 6.0") > 0) {
      window.opener = null;
      window.close();
    } else {
      window.open("", "_top");
      window.top.close();
    }
  } else if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
    window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
    //window.history.go(-2);
  } else {
    window.opener = null;
    window.open("about:blank", "_self");
    window.close();
  }
  window.location.href = "octopus://com.octopusPay.demo";
}

function getParam() {
  // let arr = "?login=gk&age=56";
  let queryStr = location.search.split("?")[1];
  let strs = queryStr.split("?")[1];
  let paramt = strs.split("&");
  console.log(paramt)
  let obj={};
  for(let i=0;i<paramt.length;i++) {
    let g = paramt[i].split("=");
    obj[g[0]] = g[1];
  }
  return obj
}
</script>


