const zhCN = {
    succeedMsg: {
        0: '成功',
    },
    errorMsg: {
        500100: '服务器端异常',
        500101: '参数错误',
        500102: '参数验证异常',
        500103: '请求是非法的',
        500104: '访问太频繁',
        500105: '对象已经存在',
        500106: '操作失败了，请重试',
        500107: '存在关联，禁止删除',
        500108: '导出异常',
        500200: '登录过期，请重新登录',
        500201: '帐号或密码不能为空',
        500202: '电话号码不能为空',
        500203: '电话号码为错误的格式',
        500204: '电话号码不存在',
        500205: '密码不正确',
        500206: '该帐户不存在',
        500207: '帐号只能包含数字、英文和下划线',
        500208: '身份验证失败',
        500209: '两个密码不一样',
        500210: '重复帐户',
        500211: '该帐户已被锁定',
        500212: '验证码失败',
        500213: '电话号码是错误的',
        500214: '没有与域名相对应的租户，联系您的管理员',
        500215: '存在相同的角色名称',
        500300: 'OCCP服务异常，请联系管理员',
        500301: '电桩不可用',
        500302: '桩配置为空',
        500303: '电池离线，请检查是否可用',
        500304: '桩端口不可用',
        500305: '枪未插入或目前的枪还没有准备好',
        500306: 'Start charging failure',
        500307: '捆绑的充电失败',
        500401: '平衡不足',
        500402: '订单不存在',
        500403: '该订单未支付',
        500404: '该订单已支付',
        500405: '订单状态不是[已支付未使用]',
        500406: '订单创建失败',
        500407: '存在未支付订单',
        500408: '订单已取消',
        500501: '商品已经秒杀完毕',
        500502: '不能重复秒杀',
        500503: '秒杀失败',
        500601: '文件不能为空',
        500602: '文件格式错误',
        500603: 'Excel工作簿是空的',
        500604: '无法读取文件'
    },
    public: {
        name: {
            nextStep: '下一步',
            status: '状态',
            login: '登录',
            logout: '登出',
            hello: '你好',
            username: '用户名',
            email: '邮箱',
            password: '密码',
            remember: '记住我',
            currentPassword: '当前密码',
            newPassword: '新密码',
            confirm_password: '确认密码',
            reset_password: '重置密码',
            captcha: '验证码',
            getCaptcha: '获取验证码',
            more: '更多',
            add: '添加',
            edit: '编辑',
            del: '删除',
            batchDel: '批量删除',
            Export: '导出',
            Import: '导入',
            DownloadTemp: '下载模板',
            ImportTips: '单击或拖动文件到此区域进行上传',
            detail: '详情',
            yes: '是',
            no: '否',
            empty: '空',
            are_you_sure_delete: '你确定需要删除该信息吗',
            action_cannot_resumed: '该操作将无法恢复',
            are_you_sure_operate: '是否确定当前操作',
            operate_remind: '当前操作敏感，请谨慎',
            search: '搜索',
            reset: '重置',
            spread: '展开',
            retract: '收回',
            show: '展示',
            no_page: '无页面',
            service: '服务商',
            phone_country_code: '电话国家代码',
            name: '名称',
            phone: '电话',
            sex: '性别',
            country: '国家',
            address: '地址',
            birthday: '出生年月日',
            remark: '备注',
            personal: '个人信息',
            enclosure: '附件',
            logo: 'Logo',
            submit: '提交',
            unknown: '未知',
            setting: '设置',
            index: '序号',
            createBy: '创建者',
            createTime: '创建时间',
            updateBy: '更新者',
            updateTime: '更新时间',
            type: '类型',
            args: '参数',
            qrcode: '二维码',
            query: '查询',
            refund: '退款',
            cancel: '取消',
            revoke: '撤销',
        },
        msg: {
            unRead: '未读',
            read: '已读',
            alarm: '告警消息',
            reminder: '提醒消息',
            advertise: '公告消息'
        },
        language: {
            zhTW: '中文(繁体)',
            enUS: '英文',
            zhCN: '中文(简体)',
            daDK: '丹麦语',
            idID: '印度尼西亚语',
            msMY: '马来语',
            thTH: '泰语',
            viVN: '越南语',
            frFR: "法语",
            plPL: "波兰语",
            it: "意大利语",
        },
        timeType: {
            0: '年',
            1: '月',
            2: '周',
            3: '日',
        },
        promptsToSelectTime: '请选择时间',
        pleaseSelect: '请选择',
        pileStatus: {
            0: '可用的',
            1: '充电中',
            2: '不可用',
        },
        isTrue: {
            0: '否',
            1: '是',
        },
        // 电流类型
        chargerType: {
            0: '交流电',
            1: '直流电',
        },
        // 注册类型
        registerType: {
            0: '邮箱',
            1: '电话',
        },
        // 进度状态
        doingStatus:{
          0: '待处理',
          1: '处理中',
          2: '已完成',
          3: '已取消',
          4: '已到期',
          5: '终止',
        },
        // 消费类型
        consumeType: {
            0: '充值',
            1: '充电消费',
            2: '充电取消',
            3: '账变',
            4: '购买计划',
            5: '取消计划',
            6: '续费计划',
            7: '退款',
            8: '罚款单',
            9: '取消罚款',
            10: '提现',
            11: '满赠',
            12: '撤销',
        },
        // 支付状态
        payStatus: {
            0: '未付款',
            1: '已付款',
            2: '消费中',
            3: '已完成',
            4: '取消'
        },
        // 充电类型
        chargeType: {
            0: '按时间',
            1: '按电量',
            2: '按用户计划',
        },
        status: {
            0: '未知',
            1: '正常',
            2: '禁用',
            3: '已删除',
        },
        sex: {
            1: '男',
            2: '女',
            3: '未知'
        },
        placeholder: {
            please_select: '请选择',
        },
        table: {
            name: '名称',
            status: '状态',
            action: '操作',
            content: '内容',
            created_at: '创建时间',
            image: '图像',
            email: 'E-mail',
            order_id: '排序',
            sex: '性别',
            last_ip: '最后登录IP',
            phone: '电话',
        },
        field: {
            name: '名称',
            username: '用户名',
            content: '内容',
            phone_country_code: '电话国家代码',
            phone: '电话',
            email: 'E-mail',
            order_id: '排序',
            portrait: '头像',
            status: '状态',
            image: '图像',
            sex: '性别',
        },
        validate: {
            required: {
                id: '缺少ID',
                username: '请输入用户名',
                password: '请输入密码',
                repassword: '请重复密码',
                name: '请输入名称',
                phone: '请输入电话',
                email: '请输入邮箱',
                portrait: '请上传头像',
                phone_country_code: '请选择电话国家代码',
            },
            tips: {
                inconsistent_password: '密码不一致',
                length_should: '长度应为{min}至{max}'
            }
        }
    },
    language: {
        zhTW: '中文（繁体）',
        enUS: '英语',
        zhCN: '中文（简体）',
        daDK: '丹麦语',
        idID: '印度尼西亚语',
        msMY: '马来语',
        thTH: '泰语',
        viVN: '越南语',
        frFR: "法语",
        plPL: "波兰语",
        it: "意大利语",
    },
    nav: {
        name: {
            Dashboard: '仪錶盘',
            RegionDashboard: '电桩概览',
            BigScreen: '大屏监控',

            TenantManage: '租户管理',
            SuperTenantManage: '超级租户',
            TenantInfo: '租户管理',
            TenantPackage: '租户套餐',

            ChargeStationImagesManage: '充电桩图片管理',
            ChargingStationManage: '充电桩管理',
            ConnectorManage: '连接端管理',

            OrderManage: '订单管理',
            PlanManage: '充电计划管理',
            RegionManage: '区域管理',
            RegionImagesManage: '充电桩图片管理',
            RepairLogManage: '维修记录管理',
            RoleManage: '角色管理',
            TransactionLogManage: '事务记录管理',
            UserManage: '用户管理',
            UserPlanManage: '用户计划管理',
            GetConfiguration: '获取电桩配置信息',
            DataTransfer: '设定电桩参数',

            TenantManagement: '系统管理',
            AuthorityManage: '权限管理',
            EmployeeManage: '用户管理',
            NoticeManage: '通讯管理',
            PublicityManage: '通告管理',

            ChargerSetting: '充电桩设定',
            MeterManage: '电表管理',
            StatisticsManage: '统计情况',
            ServiceManage: '周边服务',
            CardManage: '卡号管理',

            AppManagement: 'App数据管理',
            BalanceLogManage: '账变记录管理',
            CarManage: '车辆管理',
            CarImagesManage: '车图管理',
            AppVersionManage: 'App版本管理',
            WallerManage: '账户额度',

            LogManagement: '日志管理',
            EventLogManage: '事件记录管理',
            ConnectorLogManage: '端口日志',

            iPadManagement: 'iPad信息管理',
            iPadManage: 'iPad管理',
        },
        table: {
            order_id: '排序',
            url: 'Url',
        },
        field: {
            pid: 'PID',
            order_id: '排序',
            name: '名称',
            url: 'Url',
            status: '状态',
            icon: '图标',
        },
    },
    login: {
        validate: {
            required: {
                username: '请输入用户名',
                email: '请输入邮箱',
                password: '请输入密码',
                captcha: '请输入验证码',
            }
        }
    },
    authority: {
        name: {
            title: '权限',
            detailTitle: '权限详情',
            editTitle: '编辑权限'
        },
        table: {
            id_aut: 'ID',
            a_id_aut: '父权限',
            name: '权限',
            resource_name: '权限标识',
            type: '权限类型',
            sort: '排序',
        },
        field: {
            id_aut: 'ID',
            a_id_aut: '父权限',
            name: '权限',
            resource_name: '权限标识',
            type: '权限类型',
            sort: '排序',
        },
        validate: {
            required: {
                id_aut: '请填写ID',
                a_id_aut: '请填写父权限',
                name: '请填写权限',
                resource_name: '请填写权限标识',
                type: '请填写权限类型',
                sort: '请填写排序',
            }
        },
    },
    balanceLog: {
        rechargePackage: '充值套餐',
        addRule: '添加规则',
        gift: {
            enable: '是否开启',
            percentage: '按百分比',
            star: '起始值',
            end: '结束值',
            give: '额度',
        },
        vGift: {
            enable: '请选择是否启用',
            percentage: '请选择是否按百分比',
            star: '请输入起始值',
            end: '请输入结束值',
            give: '请输入额度',
        },
        name: {
            title: '账变记录',
            detailTitle: '账变记录详情',
            editTitle: '编辑账变记录',
            ticket: '罚款单',
            cancelFines: '取消罚款'
        },
        table: {
            id_bal_log: 'ID',
            id_use: '用户',
            type: '类型',
            amount: '金额',
            log_time: '时间',
            tenant_key: '租户识别码',
            status: '状态',
            payment_method: '支付方式',
        },
        field: {
            id_bal_log: 'ID',
            id_use: '用户',
            type: '类型',
            amount: '金额',
            log_time: '时间',
            tenant_key: '租户识别码',
            status: '状态',
            payment_method: '支付方式',
        },
        validate: {
            required: {
                id_bal_log: '请填写ID',
                id_use: '请填写用户',
                type: '请填写类型',
                amount: '请填写金额',
                log_time: '请填写时间',
                tenant_key: '请填写租户识别码',
                status: '请填写状态',
                payment_method: '请填写支付方式',
            }
        },
    },
    car: {
        name: {
            title: '车辆',
            detailTitle: '车辆详情',
            editTitle: '编辑车辆'
        },
        table: {
            id_car: 'ID',
            id_use: '用户',
            model: '型号',
            car_license: '车牌',
            max_years_old: '最大年份',
            manufacture_year: '制造年份',
            maximum_power: '最大容量',
            is_second_hand: '是否二手',
            vehicle_inspection_date: '检修日期',
            insurance_expiry_date: '保险到期日',
            tenant_key: '租户识别码',
        },
        field: {
            id_car: 'ID',
            id_use: '用户',
            model: '型号',
            car_license: '车牌',
            max_years_old: '最大年份',
            manufacture_year: '制造年份',
            maximum_power: '最大容量',
            is_second_hand: '是否二手',
            vehicle_inspection_date: '检修日期',
            insurance_expiry_date: '保险到期日',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_car: '请填写ID',
                id_use: '请填写用户',
                model: '请填写型号',
                car_license: '请填写车牌',
                max_years_old: '请填写最大年份',
                manufacture_year: '请填写制造年份',
                maximum_power: '请填写最大容量',
                is_second_hand: '请填写是否二手',
                vehicle_inspection_date: '请填写检修日期',
                insurance_expiry_date: '请填写保险到期日',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    carImages: {
        name: {
            title: '车图',
            detailTitle: '车图详情',
            editTitle: '编辑车图'
        },
        table: {
            id_car_ima: 'ID',
            id_car: '型号',
            url: '链接',
            upload_time: '上传时间',
            tenant_key: '租户识别码',
        },
        field: {
            id_car_ima: 'ID',
            id_car: '型号',
            url: '链接',
            upload_time: '上传时间',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_car_ima: '请填写ID',
                id_car: '请填写型号',
                url: '请填写链接',
                upload_time: '请填写上传时间',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    chargeStationImages: {
        name: {
            title: '充电桩图片',
            detailTitle: '充电桩图片详情',
            editTitle: '编辑充电桩图片'
        },
        table: {
            id_ima: 'ID',
            id_cha_poi: '所属电桩',
            url: '链接',
            upload_time: '上传时间',
            tenant_key: '租户识别码',
        },
        field: {
            id_ima: 'ID',
            id_cha_poi: '所属电桩',
            url: '链接',
            upload_time: '上传时间',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_ima: '请填写ID',
                id_cha_poi: '请填写所属电桩',
                url: '请填写链接',
                upload_time: '请填写上传时间',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    chargingStation: {
        tooltip: {
            postpaid: "设置充电订单先后付款，默认为按区域设置",
            billingMethod: "设置充电订单计费方式，默认为按区域设置",
        },
        billingMethodType: {
            0:"按时间",
            1:"按电量",
            2:"按用户计划",
            3:"按APP",
            4:"按区域",
        },
        updateFirmware: {
            identifier: "序列号",
            location: "远程地址",
            retrieveDate: "检索日期",
        },
        uValidate: {
            identifier: "请输入序列号",
            location: "请输入远程地址",
            retrieveDate: "请选择检索日期",
        },
        setting: {
            index: "序号",
            readonly: "是否只读",
            key: "设置键",
            value: "设置值",
        },
        settingField: {
            readonly: "请选择是否只读",
            key: "设置键不能为空",
            value: "设置值不能为空",
        },
        name: {
            title: '充电桩',
            detailTitle: '充电桩详情',
            editTitle: '编辑充电桩',
            quickAdd: '添加充电桩',
        },
        table: {
            id_cha_poi: 'ID',
            id_met: '所属电表',
            charge_box_id: '电桩ID',
            firmwareVersion: '固件版本',
            last_heartbeat_time: '上次心跳时间',
            monthly_fee: '系统接入月费',
            charger_type: '充电桩类型',
            tenant_key: '租户识别码',
            name: '名称',
            heartbeat_interval: '心跳间隔',
            enable: '是否可用',
            maxCurrent: "最大电流",
            minCurrent: "最小电流",
            isPrivate: "是否私有",
            isReadMeter: "是否读表",
            isReset: "是否重启(重启才能生效)",
            action: {
                updateFirmware: "固件升级",
                hardReset: "强制重启",
                softReset: "优雅重启"
            }
        },
        validate: {
            id_cha_poi: '请填写ID',
            id_met: '请填写所属电表',
            charge_box_id: '请填写电桩ID',
            last_heartbeat_time: '请填写上次心跳时间',
            monthly_fee: '请填写系统接入月费',
            charger_type: '请填写充电桩类型',
            tenant_key: '请填写租户识别码',
            name: '请填写名称',
            heartbeat_interval: '请填写心跳间隔',
            enable: '请填写是否可用',
            maxCurrent: "请填写最大电流",
            minCurrent: "请填写最小电流",
        },
    },
    connector: {
        name: {
            title: '连接端',
            detailTitle: '连接端详情',
            editTitle: '编辑连接端',
            startTransaction: '开始充电',
            stopTransaction: '停止充电',
        },
        table: {
            id_con: 'ID',
            id_cha_poi: '所属电桩',
            name: '名称',
            connector_id: '端口ID',
            status: '连接端状态',
            tenant_key: '租户识别码',
            power: '功率(kw)',
            type: '类型',
        },
        field: {
            id_con: 'ID',
            id_cha_poi: '所属电桩',
            name: '名称',
            connector_id: '端口ID',
            status: '连接端状态',
            tenant_key: '租户识别码',
            power: '功率(kw)',
            type: '类型',
        },
        validate: {
            required: {
                id_con: '请填写ID',
                id_cha_poi: '请填写所属电桩',
                name: '请填写名称',
                connector_id: '请填写端口ID',
                status: '请填写连接端状态',
                tenant_key: '请填写租户识别码',
                power: '请填写功率(kw)',
                type: '请填写类型',
            }
        },
    },
    employee: {
        name: {
            title: '用户',
            detailTitle: '用户详情',
            editTitle: '编辑用户'
        },
        table: {
            id_emp: 'ID',
            id_rol: '角色',
            account: '账号',
            password: '密码',
            salt: '盐',
            last_login_time: '上次登录时间',
            register_time: '创建时间',
            login_failure_counter: '登录错误计数',
            email: '邮箱',
            whatsAppPhone: 'WhatsApp 号码',
            remark: '备注',
            status: '状态',
            name: '姓名',
            commission_rate: '电价分成比例',
            yedpay_api_key: 'Yedpay API密钥',
            yedpay_sign_key: 'Yedpay 签名密钥',
            tenant_key: '租户识别码',
        },
        field: {
            id_emp: 'ID',
            id_rol: '角色',
            account: '账号',
            password: '密码',
            salt: '盐',
            last_login_time: '上次登录时间',
            register_time: '创建时间',
            login_failure_counter: '登录错误计数',
            email: '邮箱',
            whatsAppPhone: 'WhatsApp 号码',
            remark: '备注',
            status: '状态',
            name: '姓名',
            commission_rate: '电价分成比例',
            yedpay_api_key: 'Yedpay API密钥',
            yedpay_sign_key: 'Yedpay 签名密钥',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_emp: '请填写ID',
                id_rol: '请填写角色',
                account: '请填写账号',
                password: '请填写密码',
                salt: '请填写盐',
                last_login_time: '请填写上次登录时间',
                register_time: '请填写创建时间',
                login_failure_counter: '请填写登录错误计数',
                email: '请填写邮箱',
                whatsAppPhone: '请填写WhatsApp号码',
                remark: '请填写备注',
                status: '请填写状态',
                name: '请填写姓名',
                commission_rate: '请填写电价分成比例',
                yedpay_api_key: '请填写Yedpay API密钥',
                yedpay_sign_key: '请填写Yedpay 签名密钥',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    eventLog: {
        name: {
            title: '事件记录',
            detailTitle: '事件记录详情',
            editTitle: '编辑事件记录'
        },
        table: {
            id_env_log: 'ID',
            run_time: '执行耗时(ms)',
            class_method: '执行方法',
            event_time: '事件时间',
            log: '日志',
            tenant_key: '租户识别码',
            resCode: '响应码',
        },
        field: {
            id_env_log: 'ID',
            event_time: '事件时间',
            log: '日志',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_env_log: '请填写ID',
                class_method: '请填写执行方法',
                event_time: '请填写事件时间',
                log: '请填写日志',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    meter: {
        name: {
            title: '电表',
            detailTitle: '电表详情',
            editTitle: '编辑电表'
        },
        table: {
            id_met: 'ID',
            id_reg: '所属区域',
            name: '电表名称',
            current_threshold: '电流阈值',
            tenant_key: '租户识别码',
            loadByTactics: '开启策略'
        },
        field: {
            id_met: 'ID',
            id_reg: '所属区域',
            name: '电表名称',
            current_threshold: '电流阈值',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_met: '请填写ID',
                id_reg: '请填写所属区域',
                name: '请填写电表名称',
                current_threshold: '请填写电流阈值',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    order: {
        name: {
            title: '订单',
            detailTitle: '订单详情',
            editTitle: '编辑订单',
            cancel: '取消订单',
            cancelAndRefund: '取消订单并退款',
        },
        table: {
            id_ord: 'ID',
            id_car: '型号',
            id_con: '所属连接端',
            order_time: '订单日期',
            type: '订单类型',
            amount: '金额',
            charge_start_time: '充电开始时间',
            charge_end_time: '充电应结束时间',
            chargeEndTimeActual: '充电实际结束时间',
            status: '状态',
            payment_time: '支付时间',
            electricity: '购买电量',
            charge_minutes: '购买时长',
            currentElectricity: '当前电量',
            ref_id_pla: '关联计划',
            price: '下单时电价',
            commission_rate: '下单时的分成比例',
            tenant_key: '租户识别码',
            id_tra_log: '充电关联记录',
            pay_type: '支付类型',
            payment_method: '支付方式',
        },
        validate: {
            id_ord: '请填写ID',
            id_car: '请填写型号',
            id_con: '请填写所属连接端',
            order_time: '请填写订单日期',
            type: '请填写订单类型',
            amount: '请填写金额',
            charge_start_time: '请填写充电开始时间',
            charge_end_time: '请填写充电结束时间',
            status: '请填写状态',
            payment_time: '请填写支付时间',
            electricity: '请填写购买充电量',
            ref_id_pla: '请填写关联计划',
            price: '请填写下单时电价',
            commission_rate: '请填写下单时的分成比例',
            tenant_key: '请填写租户识别码',
            id_tra_log: '请填写充电关联记录',
            charge_minutes: '请填写充电时长',
            pay_type: '请填写支付类型',
            payment_method: '请填写支付方式',
        },
    },
    plan: {
        name: {
            title: '充电计划',
            detailTitle: '充电计划详情',
            editTitle: '编辑充电计划'
        },
        table: {
            id_pla: 'ID',
            name: '计划名称',
            price_per_month: '价格',
            contract_period: '合同期限',
            free_charging_time_limit: '免费充电时间',
            overtime_charge_price: '超时价格',
            tenant_key: '租户识别码',
        },
        field: {
            id_pla: 'ID',
            name: '计划名称',
            price_per_month: '价格',
            contract_period: '合同期限',
            free_charging_time_limit: '免费充电时间',
            overtime_charge_price: '超时价格',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_pla: '请填写ID',
                name: '请填写计划名称',
                price_per_month: '请填写价格',
                contract_period: '请填写合同期限',
                free_charging_time_limit: '请填写免费充电时间',
                overtime_charge_price: '请填写超时价格',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    region: {
        tooltip: {
            byPowerUnit: "每一跳的步长，不满一跳则按一跳计算，若按订单实际电量计算请设置为零",
            byTimeUnit: "每一跳的步长，不满一跳则按一跳计算，若按订单实际时间计算请设置为零",

            maximumPower: "按电量充电时，单次充电的电量上限，留空或为零则不限制，默认不限制",
            maximumMinute: "按时间充电时，单次充电时间上限，留空或为零则不限制，默认不限制",
        },
        billingMethodType:{
            0: "按时间",
            1: "按电量",
            2: "按APP",
        },
        name: {
            title: '区域',
            detailTitle: '区域详情',
            editTitle: '编辑区域'
        },
        table: {
            id_reg: 'ID',
            name: '区域名称',
            ac_price_per_electricity: '交流按电量价格',
            ac_price_per_minute: '交流按分钟价格',
            lon: '经度',
            lat: '纬度',
            address: '地址',
            dc_price_per_electricity: '直流按电量价格',
            dc_price_per_minute: '直流按分钟价格',
            tenant_key: '租户识别码',
            businessAllDay: "全天营业",
            images: '图片',
            byPowerUnit: "按电量计量单位",
            byTimeUnit: "按时间计量单位",
            postpaid: "后付费",
            billingMethod: "计费方式",
            businessHours: "营业时间段",

            maximumPower: "单次充电电量上限",
            maximumMinute: "单次充电时间上限",
            directDebit: "自动扣款",

            enableTimeoutFine: "开启超时罚款",
            timeoutFine: "超时时间(min)",
            timeoutCharging: "超时计费",
        },
        validate: {
            id_reg: '请填写ID',
            name: '请填写区域名称',
            ac_price_per_electricity: '请填写交流按电量价格',
            ac_price_per_minute: '请填写交流按分钟价格',
            lon: '请填写经度',
            lat: '请填写纬度',
            address: '请填写地址',
            dc_price_per_electricity: '请填写直流按电量价格',
            dc_price_per_minute: '请填写直流按分钟价格',
            tenant_key: '请填写租户识别码',
            businessAllDay: "选择全天营业",
            byPowerUnit: "请填写按电量计量单位",
            byTimeUnit: "请填写按时间计量单位",
            postpaid: "请选择是否后付费",
            billingMethod: "请选择计费方式",
            businessHours: "选择营业时间段",

            maximumPower: "请填写单次充电电量上限",
            maximumMinute: "请填写单次充电时间上限",
            directDebit: "请选择是否自动扣款",

            enableTimeoutFine: "请选择是否开启超时罚款",
            timeoutFine: "请填写超时时间(min)",
            timeoutCharging: "请填写超时计费",
        },
    },
    regionImages: {
        name: {
            title: '充电桩图片',
            detailTitle: '充电桩图片详情',
            editTitle: '编辑充电桩图片'
        },
        table: {
            id_reg_img: 'ID',
            id_reg: '所属区域',
            url: '链接',
            upload_time: '上传时间',
            tenant_key: '租户识别码',
        },
        field: {
            id_reg_img: 'ID',
            id_reg: '所属区域',
            url: '链接',
            upload_time: '上传时间',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_reg_img: '请填写ID',
                id_reg: '请填写所属区域',
                url: '请填写链接',
                upload_time: '请填写上传时间',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    repairLog: {
        name: {
            title: '维修记录',
            detailTitle: '维修记录详情',
            editTitle: '编辑维修记录'
        },
        table: {
            id_rep_log: 'ID',
            id_car: '型号',
            amount: '金额',
            repair_date: '维修日期',
            remark: '备注',
            tenant_key: '租户识别码',
        },
        field: {
            id_rep_log: 'ID',
            id_car: '型号',
            amount: '金额',
            repair_date: '维修日期',
            remark: '备注',
            tenant_key: '租户识别码',
        },
        validate: {
            required: {
                id_rep_log: '请填写ID',
                id_car: '请填写型号',
                amount: '请填写金额',
                repair_date: '请填写维修日期',
                remark: '请填写备注',
                tenant_key: '请填写租户识别码',
            }
        },
    },
    role: {
        name: {
            title: '角色',
            detailTitle: '角色详情',
            editTitle: '编辑角色'
        },
        table: {
            id_rol: 'ID',
            name: '角色',
            remark: '备注',
        },
        field: {
            id_rol: 'ID',
            name: '角色',
            remark: '备注',
            authorityList: '权限列表'
        },
        validate: {
            required: {
                id_rol: '请填写ID',
                name: '请填写角色',
                remark: '请填写备注',
            }
        },
    },
    roleAuthority: {
        name: {
            title: 'RoleAuthority',
            detailTitle: 'RoleAuthority Detail',
            editTitle: 'Edit RoleAuthority'
        },
        table: {
            id_rol_aut: 'ID',
            id_rol: '角色',
            id_aut: '权限',
        },
        field: {
            id_rol_aut: 'ID',
            id_rol: '角色',
            id_aut: '权限',
        },
        validate: {
            required: {
                id_rol_aut: '请填写ID',
                id_rol: '请填写角色',
                id_aut: '请填写权限',
            }
        },
    },
    transactionLog: {
        name: {
            title: '事务记录',
            detailTitle: '事务记录详情',
            editTitle: '编辑事务记录'
        },
        table: {
            id_tra_log: 'ID',
            id_con: '所属连接端',
            event_time: '发生时间',
            id_tag: '用户标签',
            start_time: '事务开始时间',
            start_value: '开始值',
            stop_time: '事务结束时间',
            stop_value: '结束值',
            stop_reason: '结束原因',
            fail_reason: '失败原因',
            tenant_key: '租户识别码',
            current_electricity: '当前充电电量',
        },
        field: {
            id_tra_log: 'ID',
            id_con: '所属连接端',
            event_time: '发生时间',
            id_tag: '用户标签',
            start_time: '事务开始时间',
            start_value: '开始值',
            stop_time: '事务结束时间',
            stop_value: '结束值',
            stop_reason: '结束原因',
            fail_reason: '失败原因',
            tenant_key: '租户识别码',
            current_electricity: '当前充电电量',
        },
        validate: {
            required: {
                id_tra_log: '请填写ID',
                id_con: '请填写所属连接端',
                event_time: '请填写发生时间',
                id_tag: '请填写用户标签',
                start_time: '请填写事务开始时间',
                start_value: '请填写开始值',
                stop_time: '请填写事务结束时间',
                stop_value: '请填写结束值',
                stop_reason: '请填写结束原因',
                fail_reason: '请填写失败原因',
                tenant_key: '请填写租户识别码',
                current_electricity: '请填写当前充电电量',
            }
        },
    },
    user: {
        name: {
            title: '用户',
            detailTitle: '用户详情',
            editTitle: '编辑用户'
        },
        table: {
            id_use: 'ID',
            balance: '余额',
            account: '账号',
            password: '密码',
            register_time: '注册时间',
            email: '邮箱',
            token: '令牌',
            salt: '盐',
            tenant_key: '租户识别码',
            phone: '电话',
            register_type: '注册类型',
        },
        field: {
            id_use: 'ID',
            balance: '余额',
            account: '账号',
            password: '密码',
            register_time: '注册时间',
            email: '邮箱',
            token: '令牌',
            salt: '盐',
            tenant_key: '租户识别码',
            phone: '电话',
            register_type: '注册类型',

            chargingCard: '充电卡号',
            chargingType: '优先充电模式',
            chargingDefDuration: '卡片充电时间(min)',
            chargingDefPower: '卡片充电量(kWh)'
        },
        validate: {
            required: {
                id_use: '请填写ID',
                balance: '请填写余额',
                account: '请填写账号',
                password: '请填写密码',
                register_time: '请填写注册时间',
                email: '请填写邮箱',
                token: '请填写令牌',
                salt: '请填写盐',
                tenant_key: '请填写租户识别码',
                phone: '请填写电话',
                register_type: '请填写注册类型',

                chargingCard: '请输入充电卡号',
                chargingType: '请选择卡片优先充电模式',
                chargingDefDuration: '请输入卡片充电時間(min)',
                chargingDefPower: '请输入卡片充电量(kWh)',
            }
        },
    },
    userPlan: {
        name: {
            title: '用户计划',
            detailTitle: '用户计划详情',
            editTitle: '编辑用户计划'
        },
        table: {
            id_use: '用户',
            id_pla: '计划',
            arrearsCount: '欠费月数',
            start_date: '合同开始日期',
            tenant_key: '租户识别码',
            createType: '创建标识',
            updateByMonth: '预缴时间',

            total: '预付月数',
            amount: '预计付款金额',
            prePayData: '预计缴费月份',
        },
        validate: {
            id_use: '请填写用户',
            id_pla: '请填写计划',
            arrearsCount: '请填写欠费月数',
            start_date: '请填写合同开始日期',
            tenant_key: '请填写租户识别码',
        },
    },
    dashboard: {
        name: {
            totalChargers: '充电桩总数',
            availableChargers: '可用桩数',
            inUseChargers: '使用中桩数',
            unavailableChargers: '不可用桩数',
            portUsage: '端口使用率',

            energyUsage: '总使用电量(kWh)',
            totalRevenue: '总收入',
            totalEnergy: '总电量',
            newUser: '新用户数',
        },
    },
    appVersionManage: {
        field: {
            androidVersion: 'Android版本',
            androidDownloadLink: 'Android下载链接',
            androidComplieNumber: 'Android编译次数',
            iosVersion: 'IOS版本',
            iosBundleId: 'IOS资源号',
            iosComplieNumber: 'IOS编译次数',
            isForcedUpdate: '是否强制更新'
        },
        validate: {
            required: {
                androidVersion: '请填入Android版本',
                androidDownloadLink: '请填入Android下载链接',
                androidComplieNumber: '请填入Android编译次数',
                iosVersion: '请填入IOS版本',
                iosBundleId: '请填入IOS资源号',
                iosComplieNumber: '请填入IOS编译次数',
                isForcedUpdate: '请选择是否强制更新'
            }
        },
    },
    dataTransfer: {
        field: {
            messageId: '设置键',
            dataStr: '设置值'
        }
    },

    // 租戶管理
    superTenantManage: {
        name: {
            title: '权限',
            detailTitle: '权限详情',
            editTitle: '编辑权限'
        },
        searchForm: {
            dataSourceKey: '租户标识',
            url: '链接地址',
            username: '账户',
            tenant: '租户名',
            isEnable: '是否启用',
            isCreate: '创建标识',
        },
        searchFormPlaceholder: {
            dataSourceKey: '请输入租户标识',
            url: '请输入链接地址',
            username: '请输入账户',
            tenant: '请输入租户名',
            isEnable: '请选择是否启用',
            isCreate: '请选择创建标识',
        },
        // 列表
        table: {
            index: '序号',
            dataSourceKey: '库标识',
            url: '链接地址',
            host: '地址',
            port: '端口',
            dbName: '数据库名',
            username: '账户',
            password: '密码',
            tenant: '租户名',
            isEnable: '是否启用',
            isCreate: '创建标识',
            domain: '域名',
            action: {
                testConn: '连接',
                create: '创建',
                edit: '编辑',
                del: '删除',
            }
        },
        // 表单
        field: {
            id: 'ID',
            datasourceKey: '库标识',
            seckey: 'OCPP访问密钥',
            url: '链接地址',
            host: '地址',
            port: '端口',
            dbName: '数据库名',
            username: '用户名',
            password: '密码',
            tenant: '租户名',
            isEnable: '是否启用',
            isCreate: '创建标识',
            remark: '备注',
            domain: '域名（可多个，逗号分割）',
            onesignalKey: 'APP通知密钥',
        },
        // 表单校验
        validate: {
            required: {
                id: '请填写ID',
                dataSourceKey: '库标识',
                seckey: '请填写访问密钥',
                url: '请填写链接地址',
                host: '请填写地址',
                port: '请填写端口',
                dbName: '请填写数据库名',
                username: '请填写用户名',
                password: '请填写密码',
                tenant: '请填写租户名',
                isEnable: '请填写租户名',
                remark: '请填写备注',
                domain: '请填写域名',
                onesignalKey: '请填写APP通知密钥',
            }
        }
    },
    // iPad管理
    iPadManage: {
        action: {},
        // 表单
        field: {
            id: 'ID',
            index: '序号',
            machineCode: '机器编码',
            name: '机器名',
            regId: '区域ID',
            regName: '区域名称',
            seckey: '访问密钥',
            args: '其他参数',
            createBy: '创建者',
            createTime: '创建时间',
            updateBy: '更新者',
            updateTime: '更新时间',
            remark: '备注',
        },
        // 表单校验
        validate: {
            id: '请填写ID',
            machineCode: '请填写机器编码',
            name: '请填写机器名',
            regId: '请填写区域ID',
            seckey: '请填写访问密钥',
            args: '请填写其他参数',
            remark: '请填写备注',
        }
    },
    // 通知管理
    noticeManage: {
        noticeName: {
            0: '邮件',
            1: '电话'
        },
        noticeType: {
            0: '告警',
            1: '提醒',
            2: '公告',
            3: '超时',
        },
        // 表单
        field: {
            id: 'ID',
            index: '序号',
            toFrom: '账号',
            type: '账号类型',
            noticeType: '通知类型',
            status: '是否启用',

            createBy: '创建者',
            createTime: '创建时间',
            updateBy: '更新者',
            updateTime: '更新时间',
            remark: '备注',
        },
        // 表单校验
        validate: {
            id: '请填写ID',
            toFrom: '请填写账号',
            type: '请选择通知类型',
            noticeType: '请选择通知类型',
            status: '请选择是否启用',
            remark: '请填写备注',
        }
    },
    // 通知公告
    publicityManage: {
        publicityType: {
            0: '服务条款',
            1: '关于我们',
            2: '公告',
            3: '通知',
            4: '提醒',
        },
        // 表单
        field: {
            id: 'ID',
            index: '序号',
            type: '通告类型',
            status: '是否启用',
            title: '通告标题',
            content: '通告内容',
            tenantKey: '租户标识',

            createBy: '创建者',
            createTime: '创建时间',
            updateBy: '更新者',
            updateTime: '更新时间',
            remark: '备注',
        },
        // 表单校验
        validate: {
            id: '请填写ID',
            type: '请选择通告类型',
            status: '请选择是否启用',
            title: '请填写通告标题',
            content: '请填写通告内容',
            remark: '请填写备注',
        }
    },
    statisticsManage: {
        table: {
            index: '序号',
            identifier: '电桩编号',
            useNum: '使用次数',
            chargeCapacity: '总用量(kw)',

            startTime: '开始时间',
            endTime: '结束时间'
        },
        validate: {
            identifier: '请输入电桩编号',
            startTime: '请选择开始时间',
            endTime: '请选择结束时间'
        }
    },
    serviceManage: {
        type: {
            0: '饮料',
            1: '食物',
            2: '厕所',
            3: '购物',
        },
        table: {
            id: 'ID',
            type: '类型',
            status: '状态',
            name: '名称',
            lng: '经度',
            lat: '纬度',
            address: '地址',
            description: '描述',
            reId: '关联区域ID'
        },
        validate: {
            type: '请选择类型',
            status: '请选择状态',
            name: '请填写名称',
            lng: '请填写经度',
            lat: '请填写纬度',
            address: '请填写地址',
            description: '请填写描述',
            reId: '关联区域'
        }
    },
    // 白牌商信息
    companyManage: {
        field: {
            id: '主键',
            tId: '关联租户ID',
            name: '白牌商名称',
            attn: '白牌商聯絡人',
            tel: '白牌商電話',
            address: '白牌商地址',
            email: '白牌商電郵',
            invoiceDate: '发票日期',
            invoiceNo: '发票编号',
            contactPerson: '联系人',
            contactTel: '联系电话',
            contactEmail: '联系邮箱',
            monthlyFee: '系统接入月费预设值',
            company: '公司信息'
        },
        validate: {
            id: '请输入id',
            tId: '请输入关联租户ID',
            name: '请输入白牌商名称',
            attn: '请输入白牌商聯絡人',
            tel: '请输入白牌商電話',
            address: '请输入白牌商地址',
            email: '请输入白牌商電郵',
            invoiceDate: '请输入发票日期',
            invoiceNo: '请输入发票编号',
            contactPerson: '请输入联系人',
            contactTel: '请输入联系电话',
            contactEmail: '请输入联系邮箱',
            monthlyFee: '请输入系统接入月费预设值'
        }
    },
    tenantInfo: {
        table: {
            id: 'ID',
            pid: '父租户ID',
            tenantKey: '租户标识',
            name: '租户名称',
            domain: '绑定域名',
            tpId: '关联套餐',
            authId: '关联的权限',
            level: '级别',
            contact: '联系人',
            email: '联系邮箱',
            phone: '联系电话',
            startTime: '启用时间',
            expireTime: '过期时间',
        },
        args:{
            yedPayExtendParams: 'YedPay参数',
            yedPayApiKey: 'YedPay ApiKey',
            yedPaySingKey: 'YedPay SingKey',

            mPayExtendParams: 'MPay参数',
            mPayPubKey: 'MPay公钥',
            mPayPriKey: 'MPay私钥',
        },
        validate: {
            id: '请输入ID',
            pid: '请输入父租户ID',
            tenantKey: '请填写租户标识',
            name: '请填写租户名称',
            domain: '请填写绑定的域名',
            tpId: '请选择关联套餐',
            authId: '请选择关联的权限',
            contact: '请填写联系人',
            email: '请填写联系邮箱',
            phone: '请填写联系电话',
            startTime: '请选择启用时间',
            expireTime: '请选择过期时间',

            yedPayExtendParams: '请输入YedPay参数',
            yedPayApiKey: '请输入YedPay ApiKey',
            yedPaySingKey: '请输入YedPay SingKey',
            mPayExtendParams: '请输入MPay参数',
            mPayPubKey: '请输入MPay公钥',
            mPayPriKey: '请输入MPay私钥',
        }
    },
    tenantPackage: {
        table: {
            id: 'ID',
            packageNo: '套餐编号',
            name: '套餐名称',
            createTenantMax: '可创建租户数',
            createUserMax: '可创建用户数',
            createAppMax: '可注册APP数',
            expireLong: '套餐有效时长',
            tenantKey: '租户标识',
        },
        validate: {
            id: '请输入ID',
            packageNo: '请输入套餐编号',
            name: '请输入套餐名称',
            createTenantMax: '请输入可创建租户数',
            createUserMax: '请输入可创建用户数',
            createAppMax: '请输入可注册APP数量',
            expireLong: '请选择套餐有效时长',
            tenantKey: '请输入租户标识',
        }
    },
    card: {
        cardType: {
            0: '白名单',
            1: '黑名单',
            2: '系统卡',
            3: '用户卡',
        },
        table: {
            cardId: 'ID',
            cardNo: '卡号',
            cardType: '类型',
            cardStatus: '是否启用',
        },
        validate: {
            cardId: '请填写ID',
            cardNo: '请填写卡号',
            cardType: '请选择类型',
            cardStatus: '请选择状态',
        }
    },
    waller: {
        wType: {
            1: '余额',
            2: '赠额',
            3: '积分'
        },
        table: {
            walletId: 'ID',
            account: '账户',
            type: '类型',
            status: '是否冻结',
            balance: '余额',
        },
        validate: {
            account: '请填写账户',
            type: '请选择类型',
            status: '请选择状态',
        }
    }
}

export default zhCN