const zhTW = {
    succeedMsg: {
        0: '成功',
    },
    errorMsg: {
        500100: '服務端異常',
        500101: '參數錯誤',
        500102: '參數校驗異常',
        500103: '請求非法',
        500104: '訪問太頻繁',
        500105: '对象已存在',
        500106: '操作失敗，請重試',
        500107: '存在關聯，禁止刪除',
        500108: '匯出異常',

        500200: '登錄過期，請重新登錄',
        500201: '帳號或密碼不能為空',
        500202: '手機號不能為空',
        500203: '手機號格式錯誤',
        500204: '手機號不存在',
        500205: '密碼錯誤',
        500206: '帳號不存在',
        500207: '帳號只能包含數位、英文和下劃線',
        500208: '認證失敗',
        500209: '兩個密碼並不相同',
        500210: '重複帳號',
        500211: '該帳號已被鎖定',
        500212: '驗證碼失敗',
        500213: '電話號碼錯誤',
        500214: '不存在與該域名對應的租戶，請聯絡管理員',
        500215: '存在相同角色名稱',

        500300: 'OCCP服务异常，请联系管理员',
        500301: '電樁不可用',
        500302: '電樁配置为空',
        500303: '電樁离线，请检查其是否可用',
        500304: '電樁埠不可用',
        500305: '未插入電槍或當前電槍為非準備狀態',
        500306: '開始充電失敗',
        500307: '結束充電失敗',

        500401: '餘額不足',
        500402: '訂單不存在',
        500403: '訂單未支付',
        500404: '訂單已支付',
        500405: '訂單狀態非[已支付未使用]',
        500406: '訂單創建失敗',
        500407: '存在未支付訂單',
        500408: '訂單已取消',

        500501: '商品已經秒殺完畢',
        500502: '不能重複秒殺',
        500503: '秒殺失敗',

        500601: '檔不能為空',
        500602: '文件格式錯誤',
        500603: 'Excel工作簿為空',
        500604: '讀取文件失敗',
    },
    public: {
        name: {
            nextStep: '下一步',
            status: "狀態",
            login: "登錄",
            logout: "登出",
            hello: "你好",
            username: "用戶名",
            email: "郵箱",
            password: "密碼",
            remember: '記住我',
            currentPassword: '當前密碼',
            newPassword: '新密碼',
            confirm_password: "確認密碼",
            reset_password: "重置密碼",
            captcha: "驗證碼",
            getCaptcha: '獲取驗證碼',
            more: "更多",
            add: "添加",
            edit: "編輯",
            del: "刪除",
            batchDel: "批量刪除",
            Export: '匯出',
            Import: '導入',
            DownloadTemp: '下載範本',
            ImportTips: '按一下或拖動檔案到此區域進行上傳',
            detail: "詳情",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你確定需要刪除該信息嗎",
            action_cannot_resumed: "該操作將無法恢復",
            are_you_sure_operate: '是否確定當前操作',
            operate_remind: '當前操作敏感，請謹慎',
            search: "搜索",
            reset: "重置",
            spread: "展開",
            retract: "收回",
            show: "展示",
            no_page: "無頁麵",
            service: "服務商",
            phone_country_code: "電話國家代碼",
            name: "名稱",
            phone: "電話",
            sex: "性別",
            country: "國家",
            address: "地址",
            birthday: "出生年月日",
            remark: "備註",
            personal: "個人信息",
            enclosure: "附件",
            logo: "Logo",
            submit:"提交",
            unknown: "未知",
            setting: '設置',
            index: '序號',
            createBy: "創建者",
            createTime: "創建時間",
            updateBy: "更新者",
            updateTime: "更新時間",
            type: '類型',
            args: '參數',
            qrcode: '二維碼',
            query: '査詢',
            refund: '退款',
            cancel: '取消',
            revoke: '撤銷',
        },
        msg: {
            unRead: '未讀',
            read: '已讀',
            alarm: '告警消息',
            reminder: '提醒消息',
            advertise: '公告消息'
        },
        language: {
            zhTW: "中文（繁體）",
            enUS: "英文",
            zhCN: "中文（簡體）",
            daDK: "丹麥語",
            idID: "印度尼西亞語",
            msMY: "馬來語",
            thTH: "泰語",
            viVN: "越南語",
            frFR: "法語",
            plPL: "波蘭語",
            it: "義大利語",
        },
        timeType: {
            0: '年',
            1: '月',
            2: '周',
            3: '日',
        },
        promptsToSelectTime: '請選擇時間',
        pleaseSelect: '請選擇',
        pileStatus: {
            0: '可用的',
            1: '充電中',
            2: '不可用',
        },
        isTrue: {
            0: "否",
            1: "是",
        },
        chargerType: {
            0: "交流電",
            1: "直流電",
        },
        registerType:{
            0: "郵箱",
            1: "電話",
        },
        doingStatus:{
            0: '待處理',
            1: '處理中',
            2: '已完成',
            3: '已取消',
            4: '已到期',
            5: '終止',
        },
        consumeType: {
            0: "充值",
            1: "充電消費",
            2: "充電取消",
            3: "賬變",
            4: '購買計畫',
            5: '取消計畫',
            6: '續費計畫',
            7: '退款',
            8: '罰款單',
            9: '取消罰款',
            10: '提現',
            11: '滿贈',
            12: '撤銷',
        },
        payStatus: {
            0: "未付款",
            1: "已付款",
            2: "消費中",
            3: "已完成",
            4: "取消",
        },
        chargeType: {
            0: "按時間",
            1: "按電量",
            2: "按用戶計劃",
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已刪除",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "請選擇",
        },
        table: {
            name: "名稱",
            status: "狀態",
            action: "操作",
            content: "內容",
            created_at: "創建時間",
            image: "圖像",
            email: "郵箱",
            order_id: "排序",
            sex: "性別",
            last_ip: "最後登錄IP",
            phone: "電話",
        },
        field: {
            name: "名稱",
            username: "用戶名",
            content: "內容",
            phone_country_code: "電話國家代碼",
            phone: "電話",
            email: "郵箱",
            order_id: "排序",
            portrait: "頭像",
            status: "狀態",
            image: "圖像",
            sex: "性別",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "請輸入用戶名",
                password: "請輸入密碼",
                repassword: "請重復密碼",
                name: "請輸入名稱",
                phone: "請輸入電話",
                email: "請輸入郵箱",
                portrait: "請上傳頭像",
                phone_country_code: "請選擇電話國家代碼",
            },
            tips: {
                inconsistent_password: "密碼不一緻",
                length_should: "長度應爲{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁體）",
        enUS: "英語",
        zhCN: "中文（簡體）",
        daDK: "丹麥語",
        idID: "印度尼西亞語",
        msMY: "馬來語",
        thTH: "泰語",
        viVN: "越南語",
        frFR: "法語",
        plPL: "波蘭語",
        it: "義大利語",
    },
    nav: {
        name: {
            Dashboard: "儀錶盤",
            RegionDashboard: '電樁概覽',
            BigScreen: '大屏監控',

            BalanceLogManage: "賬變記錄管理",
            CarManage: "車輛管理",
            CarImagesManage: "車圖管理",
            ChargeStationImagesManage: "充電樁圖片管理",
            ChargingStationManage: "充電樁管理",
            ConnectorManage: "連接端管理",
            EmployeeManage: "用戶管理",
            ConnectorLogManage: '端口日志',
            EventLogManage: "事件記錄管理",
            MeterManage: "電錶管理",
            OrderManage: "訂單管理",
            PlanManage: "充電計劃管理",
            RegionManage: "區域管理",
            RegionImagesManage: "停車場圖片管理",
            RepairLogManage: "維修記錄管理",
            RoleManage: "角色管理",
            TransactionLogManage: "事務記錄管理",
            UserManage: "用戶管理",
            UserPlanManage: "用戶計劃管理",
            GetConfiguration: "獲取電樁配置信息",
            DataTransfer: "設定電樁參數",

            TenantManage: "租戶管理",
            SuperTenantManage: "超級租戶",
            TenantInfo: '租戶管理',
            TenantPackage: '租戶套餐',

            TenantManagement: "系統管理",
            AuthorityManage: "權限管理",
            NoticeManage: "通知管理",
            PublicityManage: '通告管理',

            ChargerSetting: "充電樁設定",
            StatisticsManage: '統計情況',
            ServiceManage: '周邊服務',
            CardManage: '卡號管理',

            AppManagement: "App數據管理",
            LogManagement: "日誌管理",
            AppVersionManage: "App版本管理",
            WallerManage: '帳戶額度',

            iPadManagement: "iPad信息管理",
            iPadManage: "iPad管理",
        },
        table: {
            order_id: "排序",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "排序",
            name: "名稱",
            url: "Url",
            status: "狀態",
            icon: "圖標",
        },
    },
    login: {
        validate: {
            required: {
                username: "請輸入用戶名",
                email: "請輸入郵箱",
                password: "請輸入密碼",
                captcha: "請輸入驗證碼",
            }
        }
    },
    authority:{
        name: {
            title: "權限",
            detailTitle: "權限詳情",
            editTitle: "編輯權限"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "父權限",
            name: "權限",
            resource_name: "權限標識",
            type: "權限類型",
            sort: "排序",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "父權限",
            name: "權限",
            resource_name: "權限標識",
            type: "權限類型",
            sort: "排序",
        },
        validate: {
            required: {
                id_aut: "請填寫ID",
                a_id_aut: "請填寫父權限",
                name: "請填寫權限",
                resource_name: "請填寫權限標識",
                type: "請填寫權限類型",
                sort: "請填寫排序",
            }
        },
    },
    balanceLog:{
        rechargePackage: '充值套餐',
        addRule: '添加規則',
        gift: {
            enable: '是否開啟',
            percentage: '按百分比',
            star: '起始值',
            end: '結束值',
            give: '額度',
        },
        vGift: {
            enable: '請選擇是否啟用',
            percentage: '請選擇是否按百分比',
            star: '請輸入起始值',
            end: '請輸入結束值',
            give: '請輸入額度',
        },
        name: {
            title: "賬變記錄",
            detailTitle: "賬變記錄詳情",
            editTitle: "編輯賬變記錄",
            ticket: '罰款單',
            cancelFines: '取消罰款'
        },
        table: {
            id_bal_log: "ID",
            id_use: "用戶",
            type: "類型",
            amount: "金額",
            log_time: "時間",
            tenant_key: "租戶識別碼",
            status: "狀態",
            payment_method: "支付方式",
        },
        field: {
            id_bal_log: "ID",
            id_use: "用戶",
            type: "類型",
            amount: "金額",
            log_time: "時間",
            tenant_key: "租戶識別碼",
            status: "狀態",
            payment_method: "支付方式",
        },
        validate: {
            required: {
                id_bal_log: "請填寫ID",
                id_use: "請填寫用戶",
                type: "請填寫類型",
                amount: "請填寫金額",
                log_time: "請填寫時間",
                tenant_key: "請填寫租戶識別碼",
                status: "請填寫狀態",
                payment_method: "請填寫支付方式",
            }
        },
    },
    car:{
        name: {
            title: "車輛",
            detailTitle: "車輛詳情",
            editTitle: "編輯車輛"
        },
        table: {
            id_car: "ID",
            id_use: "用戶",
            model: "型號",
            car_license: "車牌",
            max_years_old: "最大年份",
            manufacture_year: "製造年份",
            maximum_power: "最大容量",
            is_second_hand: "是否二手",
            vehicle_inspection_date: "檢修日期",
            insurance_expiry_date: "保險到期日",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_car: "ID",
            id_use: "用戶",
            model: "型號",
            car_license: "車牌",
            max_years_old: "最大年份",
            manufacture_year: "製造年份",
            maximum_power: "最大容量",
            is_second_hand: "是否二手",
            vehicle_inspection_date: "檢修日期",
            insurance_expiry_date: "保險到期日",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_car: "請填寫ID",
                id_use: "請填寫用戶",
                model: "請填寫型號",
                car_license: "請填寫車牌",
                max_years_old: "請填寫最大年份",
                manufacture_year: "請填寫製造年份",
                maximum_power: "請填寫最大容量",
                is_second_hand: "請填寫是否二手",
                vehicle_inspection_date: "請填寫檢修日期",
                insurance_expiry_date: "請填寫保險到期日",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    carImages:{
        name: {
            title: "車圖",
            detailTitle: "車圖詳情",
            editTitle: "編輯車圖"
        },
        table: {
            id_car_ima: "ID",
            id_car: "型號",
            url: "鏈接",
            upload_time: "上傳時間",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_car_ima: "ID",
            id_car: "型號",
            url: "鏈接",
            upload_time: "上傳時間",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_car_ima: "請填寫ID",
                id_car: "請填寫型號",
                url: "請填寫鏈接",
                upload_time: "請填寫上傳時間",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    chargeStationImages:{
        name: {
            title: "充電樁圖片",
            detailTitle: "充電樁圖片詳情",
            editTitle: "編輯充電樁圖片"
        },
        table: {
            id_ima: "ID",
            id_cha_poi: "所屬電樁",
            url: "鏈接",
            upload_time: "上傳時間",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_ima: "ID",
            id_cha_poi: "所屬電樁",
            url: "鏈接",
            upload_time: "上傳時間",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_ima: "請填寫ID",
                id_cha_poi: "請填寫所屬電樁",
                url: "請填寫鏈接",
                upload_time: "請填寫上傳時間",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    chargingStation:{
        tooltip: {
            postpaid: "設定充電訂單先後付款，默認為按區域設置",
            billingMethod: "設定充電訂單計費方式，默認為按區域設置",
        },
        billingMethodType: {
            0:"按時間",
            1:"按電量",
            2:"按用戶計畫",
            3:"按APP",
            4:"按區域",
        },
        updateFirmware: {
            identifier: "序列號",
            location: "遠程位址",
            retrieveDate: "檢索日期",
        },
        uValidate: {
            identifier: "請輸入序列號",
            location: "請輸入遠程位址",
            retrieveDate: "請選擇檢索日期",
        },
        setting: {
            index: "序號",
            readonly: "是否唯讀",
            key: "設置鍵",
            value: "設置值",
        },
        settingField: {
            readonly: "請選擇是否唯讀",
            key: "設置鍵不能為空",
            value: "設置值不能為空",
        },
        name: {
            title: "充電樁",
            detailTitle: "充電樁詳情",
            editTitle: "編輯充電樁",
            quickAdd: "添加充電樁",
        },
        table: {
            id_cha_poi: "ID",
            id_met: "所屬電錶",
            charge_box_id: "電樁ID",
            firmwareVersion: '固件版本',
            last_heartbeat_time: "上次心跳時間",
            monthly_fee: "系統接入月費",
            charger_type: "充電樁類型",
            tenant_key: "租戶識別碼",
            name: "名稱",
            heartbeat_interval: "心跳間隔",
            enable: "是否可用",
            maxCurrent: "最大電流",
            minCurrent: "最小電流",
            isPrivate: "是否私有",
            isReadMeter: "是否讀錶",
            isReset: "是否重啓(重啓才能生效)",
            action: {
                updateFirmware: "固件升級",
                hardReset: "強制重啓",
                softReset: "優雅重啓"
            }
        },
        validate: {
            id_cha_poi: "請填寫ID",
            id_met: "請填寫所屬電錶",
            charge_box_id: "請填寫電樁ID",
            last_heartbeat_time: "請填寫上次心跳時間",
            monthly_fee: "請填寫系統接入月費",
            charger_type: "請填寫充電樁類型",
            tenant_key: "請填寫租戶識別碼",
            name: "請填寫名稱",
            heartbeat_interval: "請填寫心跳間隔",
            enable: "請填寫是否可用",
            maxCurrent: "請填寫最大電流",
            minCurrent: "請填寫最小電流",
        },
    },
    connector:{
        name: {
            title: "連接端",
            detailTitle: "連接端詳情",
            editTitle: "編輯連接端",
            startTransaction: "開始充電",
            stopTransaction: "停止充電",
        },
        table: {
            id_con: "ID",
            id_cha_poi: "所屬電樁",
            name: "名稱",
            connector_id: "端口ID",
            status: "連接端狀態",
            tenant_key: "租戶識別碼",
            power: "功率(kw)",
            type: "類型",
        },
        field: {
            id_con: "ID",
            id_cha_poi: "所屬電樁",
            name: "名稱",
            connector_id: "端口ID",
            status: "連接端狀態",
            tenant_key: "租戶識別碼",
            power: "功率(kw)",
            type: "類型",
        },
        validate: {
            required: {
                id_con: "請填寫ID",
                id_cha_poi: "請填寫所屬電樁",
                name: "請填寫名稱",
                connector_id: "請填寫端口ID",
                status: "請填寫連接端狀態",
                tenant_key: "請填寫租戶識別碼",
                power: "請填寫功率(kw)",
                type: "請填寫類型",
            }
        },
    },
    employee:{
        name: {
            title: "用戶",
            detailTitle: "用戶詳情",
            editTitle: "編輯用戶"
        },
        table: {
            id_emp: "ID",
            id_rol: "角色",
            account: "賬號",
            password: "密碼",
            salt: "鹽",
            last_login_time: "上次登錄時間",
            register_time: "創建時間",
            login_failure_counter: "登錄錯誤計數",
            email: "郵箱",
            whatsAppPhone: 'WhatsApp 號碼',
            remark: "備註",
            status: "狀態",
            name: "姓名",
            commission_rate: "電價分成比例",
            yedpay_api_key: "Yedpay API密鑰",
            yedpay_sign_key: "Yedpay 簽名密鑰",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_emp: "ID",
            id_rol: "角色",
            account: "賬號",
            password: "密碼",
            salt: "鹽",
            last_login_time: "上次登錄時間",
            register_time: "創建時間",
            login_failure_counter: "登錄錯誤計數",
            email: "郵箱",
            whatsAppPhone: 'WhatsApp 號碼',
            remark: "備註",
            status: "狀態",
            name: "姓名",
            commission_rate: "電價分成比例",
            yedpay_api_key: "Yedpay API密鑰",
            yedpay_sign_key: "Yedpay 簽名密鑰",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_emp: "請填寫ID",
                id_rol: "請填寫角色",
                account: "請填寫賬號",
                password: "請填寫密碼",
                salt: "請填寫鹽",
                last_login_time: "請填寫上次登錄時間",
                register_time: "請填寫創建時間",
                login_failure_counter: "請填寫登錄錯誤計數",
                email: "請填寫郵箱",
                whatsAppPhone: '請填寫WhatsApp號碼',
                remark: "請填寫備註",
                status: "請填寫狀態",
                name: "請填寫姓名",
                commission_rate: "請填寫電價分成比例",
                yedpay_api_key: "請填寫Yedpay API密鑰",
                yedpay_sign_key: "請填寫Yedpay 簽名密鑰",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    eventLog:{
        name: {
            title: "事件記錄",
            detailTitle: "事件記錄詳情",
            editTitle: "編輯事件記錄"
        },
        table: {
            id_env_log: "ID",
            run_time: '執行耗時(ms)',
            class_method: '執行方法',
            event_time: "事件時間",
            log: "日誌",
            tenant_key: "租戶識別碼",
            resCode: '響應碼',
        },
        field: {
            id_env_log: "ID",
            event_time: "事件時間",
            log: "日誌",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_env_log: "請填寫ID",
                class_method: '請填寫執行方法',
                event_time: "請填寫事件時間",
                log: "請填寫日誌",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    meter:{
        name: {
            title: "電錶",
            detailTitle: "電錶詳情",
            editTitle: "編輯電錶"
        },
        table: {
            id_met: "ID",
            id_reg: "所屬區域",
            name: "電錶名稱",
            current_threshold: "電流閾值",
            tenant_key: "租戶識別碼",
            loadByTactics: '開啟策略'
        },
        field: {
            id_met: "ID",
            id_reg: "所屬區域",
            name: "電錶名稱",
            current_threshold: "電流閾值",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_met: "請填寫ID",
                id_reg: "請填寫所屬區域",
                name: "請填寫電錶名稱",
                current_threshold: "請填寫電流閾值",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    order:{
        name: {
            title: "訂單",
            detailTitle: "訂單詳情",
            editTitle: "編輯訂單",
            cancel: '取消訂單',
            cancelAndRefund: '取消訂單並退款',
        },
        table: {
            id_ord: "ID",
            id_car: "型號",
            id_con: "所屬連接端",
            order_time: "訂單日期",
            type: "訂單類型",
            amount: "金額",
            charge_start_time: "開始時間",
            charge_end_time: "應結束時間",
            chargeEndTimeActual: '實際結束時間',
            status: "狀態",
            payment_time: "支付時間",
            electricity: "購買電量",
            charge_minutes: "購買時長",
            currentElectricity: '當前電力',
            ref_id_pla: "關聯計劃",
            price: "下單時電價",
            commission_rate: "下單時的分成比例",
            tenant_key: "租戶識別碼",
            id_tra_log: "充電關聯記錄",
            pay_type: "支付類型",
            payment_method: "支付方式",
        },
        validate: {
            id_ord: "請填寫ID",
            id_car: "請填寫型號",
            id_con: "請填寫所屬連接端",
            order_time: "請填寫訂單日期",
            type: "請填寫訂單類型",
            amount: "請填寫金額",
            charge_start_time: "請填寫開始充電時間",
            charge_end_time: "請填寫結束充電時間",
            status: "請填寫狀態",
            payment_time: "請填寫支付時間",
            electricity: "請填寫充電量",
            ref_id_pla: "請填寫關聯計劃",
            price: "請填寫下單時電價",
            commission_rate: "請填寫下單時的分成比例",
            tenant_key: "請填寫租戶識別碼",
            id_tra_log: "請填寫充電關聯記錄",
            charge_minutes: "請填寫充電時長",
            pay_type: "請填寫支付類型",
            payment_method: "請填寫支付方式",
        },
    },
    plan:{
        name: {
            title: "充電計劃",
            detailTitle: "充電計劃詳情",
            editTitle: "編輯充電計劃"
        },
        table: {
            id_pla: "ID",
            name: "計劃名稱",
            price_per_month: "價格",
            contract_period: "合同期限",
            free_charging_time_limit: "免費充電時間",
            overtime_charge_price: "超時價格",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_pla: "ID",
            name: "計劃名稱",
            price_per_month: "價格",
            contract_period: "合同期限",
            free_charging_time_limit: "免費充電時間",
            overtime_charge_price: "超時價格",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_pla: "請填寫ID",
                name: "請填寫計劃名稱",
                price_per_month: "請填寫價格",
                contract_period: "請填寫合同期限",
                free_charging_time_limit: "請填寫免費充電時間",
                overtime_charge_price: "請填寫超時價格",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    region:{
        tooltip: {
            byPowerUnit: "每一跳的步長，不滿一跳則按一跳計算，若按訂單實際電量計算請設定為零",
            byTimeUnit: "每一跳的步長，不滿一跳則按一跳計算，若按訂單實際時間計算請設定為零",

            maximumPower: "按電量充電時，單次充電的電量上限，留空或為零則不限制，默認不限制",
            maximumMinute: "按時間充電時，單次充電時間上限，留空或為零則不限制，默認不限制",
        },
        billingMethodType:{
            0: "按時間",
            1: "按電量",
            2: "按APP選擇",
        },
        name: {
            title: "區域",
            detailTitle: "區域詳情",
            editTitle: "編輯區域"
        },
        table: {
            id_reg: "ID",
            name: "區域名稱",
            ac_price_per_electricity: "交流按電量價格",
            ac_price_per_minute: "交流按分鐘價格",
            lon: "經度",
            lat: "緯度",
            address: "地址",
            dc_price_per_electricity: "直流按電量價格",
            dc_price_per_minute: "直流按分鐘價格",
            tenant_key: "租戶識別碼",
            businessAllDay: "全天營業",
            images:"圖片",
            byPowerUnit: "按電量計量單位",
            byTimeUnit: "按時間計量單位",
            postpaid: "後付費",
            billingMethod: "計費方式",
            businessHours: "營業時間段",

            maximumPower: "單次充電電量上限",
            maximumMinute: "單次充電時間上限",
            directDebit: "自動扣款",

            enableTimeoutFine: "開啟超時罰款",
            timeoutFine: "超時時間(min)",
            timeoutCharging: "超時計費",
        },
        validate: {
            id_reg: "請填寫ID",
            name: "請填寫區域名稱",
            ac_price_per_electricity: "請填寫交流按電量價格",
            ac_price_per_minute: "請填寫交流按分鐘價格",
            lon: "請填寫經度",
            lat: "請填寫緯度",
            address: "請填寫地址",
            dc_price_per_electricity: "請填寫直流按電量價格",
            dc_price_per_minute: "請填寫直流按分鐘價格",
            tenant_key: "請填寫租戶識別碼",
            businessAllDay: "請選擇全天營業",
            byPowerUnit: "請填寫按電量計量單位",
            byTimeUnit: "請填寫按時間計量單位",
            postpaid: "請選擇是否後付費",
            billingMethod: "請選擇計費方式",
            businessHours: "選擇營業時間段",

            maximumPower: "請填寫單次充電電量上限",
            maximumMinute: "請填寫單次充電時間上限",
            directDebit: "請選擇是否自動扣款",

            enableTimeoutFine: "請選擇是否開啟超時罰款",
            timeoutFine: "請填寫超時時間(min)",
            timeoutCharging: "請填寫超時計費",
        },
    },
    regionImages:{
        name: {
            title: "停車場圖片",
            detailTitle: "停車場圖片詳情",
            editTitle: "編輯停車場圖片"
        },
        table: {
            id_reg_img: "ID",
            id_reg: "所屬區域",
            url: "鏈接",
            upload_time: "上傳時間",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_reg_img: "ID",
            id_reg: "所屬區域",
            url: "鏈接",
            upload_time: "上傳時間",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_reg_img: "請填寫ID",
                id_reg: "請填寫所屬區域",
                url: "請填寫鏈接",
                upload_time: "請填寫上傳時間",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    repairLog:{
        name: {
            title: "維修記錄",
            detailTitle: "維修記錄詳情",
            editTitle: "編輯維修記錄"
        },
        table: {
            id_rep_log: "ID",
            id_car: "型號",
            amount: "金額",
            repair_date: "維修日期",
            remark: "備註",
            tenant_key: "租戶識別碼",
        },
        field: {
            id_rep_log: "ID",
            id_car: "型號",
            amount: "金額",
            repair_date: "維修日期",
            remark: "備註",
            tenant_key: "租戶識別碼",
        },
        validate: {
            required: {
                id_rep_log: "請填寫ID",
                id_car: "請填寫型號",
                amount: "請填寫金額",
                repair_date: "請填寫維修日期",
                remark: "請填寫備註",
                tenant_key: "請填寫租戶識別碼",
            }
        },
    },
    role:{
        name: {
            title: "角色",
            detailTitle: "角色詳情",
            editTitle: "編輯角色"
        },
        table: {
            id_rol: "ID",
            name: "角色",
            remark: "備註",
        },
        field: {
            id_rol: "ID",
            name: "角色",
            remark: "備註",
                authorityList: "權限列錶"
        },
        validate: {
            required: {
                id_rol: "請填寫ID",
                name: "請填寫角色",
                remark: "請填寫備註",
            }
        },
    },
    roleAuthority:{
        name: {
            title: "RoleAuthority",
            detailTitle: "RoleAuthority Detail",
            editTitle: "Edit RoleAuthority"
        },
        table: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        field: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        validate: {
            required: {
                id_rol_aut: "請填寫id_rol_aut",
                id_rol: "請填寫id_rol",
                id_aut: "請填寫id_aut",
            }
        },
    },
    transactionLog:{
        name: {
            title: "事務記錄",
            detailTitle: "事務記錄詳情",
            editTitle: "編輯事務記錄"
        },
        table: {
            id_tra_log: "ID",
            id_con: "所屬連接端",
            event_time: "發生時間",
            id_tag: "用戶標籤",
            start_time: "事務開始時間",
            start_value: "開始值",
            stop_time: "事務結束時間",
            stop_value: "結束值",
            stop_reason: "結束原因",
            fail_reason: "失敗原因",
            tenant_key: "租戶識別碼",
            current_electricity: "當前充電電量",
        },
        field: {
            id_tra_log: "ID",
            id_con: "所屬連接端",
            event_time: "發生時間",
            id_tag: "用戶標籤",
            start_time: "事務開始時間",
            start_value: "開始值",
            stop_time: "事務結束時間",
            stop_value: "結束值",
            stop_reason: "結束原因",
            fail_reason: "失敗原因",
            tenant_key: "租戶識別碼",
            current_electricity: "當前充電電量",
        },
        validate: {
            required: {
                id_tra_log: "請填寫ID",
                id_con: "請填寫所屬連接端",
                event_time: "請填寫發生時間",
                id_tag: "請填寫用戶標籤",
                start_time: "請填寫事務開始時間",
                start_value: "請填寫開始值",
                stop_time: "請填寫事務結束時間",
                stop_value: "請填寫結束值",
                stop_reason: "請填寫結束原因",
                fail_reason: "請填寫失敗原因",
                tenant_key: "請填寫租戶識別碼",
                current_electricity: "請填寫當前充電電量",
            }
        },
    },
    user:{
        name: {
            title: "用戶",
            detailTitle: "用戶詳情",
            editTitle: "編輯用戶"
        },
        table: {
            id_use: "ID",
            balance: "餘額",
            account: "賬號",
            password: "密碼",
            register_time: "註冊時間",
            email: "郵箱",
            token: "令牌",
            salt: "鹽",
            tenant_key: "租戶識別碼",
            phone: "電話",
            register_type: "註冊類型",
        },
        field: {
            id_use: "ID",
            balance: "餘額",
            account: "賬號",
            password: "密碼",
            register_time: "註冊時間",
            email: "郵箱",
            token: "令牌",
            salt: "鹽",
            tenant_key: "租戶識別碼",
            phone: "電話",
            register_type: "註冊類型",

            chargingCard: '充電卡號',
            chargingType: '優先充電模式',
            chargingDefDuration: '卡片充電時間(min)',
            chargingDefPower: '卡片充電量(kWh)'
        },
        validate: {
            required: {
                id_use: "請填寫ID",
                balance: "請填寫餘額",
                account: "請填寫賬號",
                password: "請填寫密碼",
                register_time: "請填寫註冊時間",
                email: "請填寫郵箱",
                token: "請填寫令牌",
                salt: "請填寫鹽",
                tenant_key: "請填寫租戶識別碼",
                phone: "請填寫電話",
                register_type: "請填寫註冊類型",

                chargingCard: '請填寫充電卡號',
                chargingType: '請選擇卡片優先充電模式',
                chargingDefDuration: '請填寫卡片充電時間(min)',
                chargingDefPower: '請填寫卡片充電量(kWh)',
            }
        },
    },
    userPlan:{
        name: {
            title: "用戶計劃",
            detailTitle: "用戶計劃詳情",
            editTitle: "編輯用戶計劃"
        },
        table: {
            id_use: "用戶",
            id_pla: "計劃",
            arrearsCount: '欠費月數',
            start_date: "合同開始日期",
            tenant_key: "租戶識別碼",
            createType: '創建標識',
            updateByMonth: '預繳時間',

            total: '預付月數',
            amount: '預計付款金額',
            prePayData: '預計繳費月份',
        },
        validate: {
            id_use: "請填寫用戶",
            id_pla: "請填寫計劃",
            arrearsCount: '請填寫欠費月數',
            start_date: "請填寫合同開始日期",
            tenant_key: "請填寫租戶識別碼",
        },
    },
    dashboard:{
        name: {
            totalChargers:"充電樁總數",
            availableChargers:"可用樁數",
            inUseChargers:"使用中樁數",
            unavailableChargers:"不可用樁數",
            portUsage:'埠使用率',

            energyUsage:"總使用電量(kWh)",
            totalRevenue: "總收入",
            totalEnergy: "總電量",
            newUser: "新用戶數",
        },
        table: {
        },
        field: {
        },
        validate: {
            required: {
            }
        },
    },
    appVersionManage:{
        field: {
            androidVersion:"Android版本",
            androidDownloadLink:"Android下載鏈接",
            androidComplieNumber:"Android編譯次數",
            iosVersion:"IOS版本",
            iosBundleId:"IOS資源號",
            iosComplieNumber:"IOS編譯次數",
            isForcedUpdate: '是否强制更新'
        },
        validate: {
            required: {
                androidVersion:"請填入Android版本",
                androidDownloadLink:"請填入Android下載鏈接",
                androidComplieNumber:"請填入Android編譯次數",
                iosVersion:"請填入IOS版本",
                iosBundleId:"請填入IOS資源號",
                iosComplieNumber:"請填入IOS編譯次數",
                isForcedUpdate: '請選擇是否强制更新'
            }
        },
    },
    dataTransfer: {
        field: {
            messageId: "設置鍵",
            dataStr: "設置值"
        }
    },
    superTenantManage:{
      name: {
          title: "權限",
          detailTitle: "權限詳情",
          editTitle: "編輯權限"
      },
      searchForm: {
          dataSourceKey: "租戶標識",
          url: "鏈接地址",
          username: "賬戶",
          tenant: "租戶名",
          isEnable: "是否啓用",
          isCreate: "創建標識",
      },
      searchFormPlaceholder: {
          dataSourceKey: "請輸入租戶標識",
          url: "請輸入鏈接地址",
          username: "請輸入賬戶",
          tenant: "請輸入租戶名",
          isEnable: "請選擇是否啓用",
          isCreate: "請選擇創建標識",
      },
      // 列錶
      table: {
          index: "序號",
          dataSourceKey: "庫標識",
          url: "鏈接地址",
          host: "地址",
          port: "端口",
          dbName: "數據庫名",
          username: "賬戶",
          password: "密碼",
          tenant: "租戶名",
          isEnable: "是否啓用",
          isCreate: "創建標識",
          domain: "域名",
          action: {
              testConn: "連接",
              create: "創建",
              edit: "編輯",
              del: "刪除",
          }
      },
      // 錶單
      field: {
          id: "ID",
          datasourceKey: "庫標識",
          seckey: "訪問密鑰",
          url: "鏈接地址",
          host: "地址",
          port: "端口",
          dbName: "數據庫名",
          username: "用戶名",
          password: "密碼",
          tenant: "租戶名",
          isEnable: "是否啓用",
          isCreate: "創建標識",
          remark: "備註",
          domain: "域名（可多個，逗號分割）",
          onesignalKey: 'APP通知金鑰',
      },
      // 錶單校驗
      validate: {
          required: {
              id: "請填寫ID",
              dataSourceKey: "庫標識",
              seckey: "請填寫訪問密鑰",
              url: "請填寫鏈接地址",
              host: "請填寫地址",
              port: "請填寫端口",
              dbName: "請填寫數據庫名",
              username: "請填寫用戶名",
              password: "請填寫密碼",
              tenant: "請填寫租戶名",
              isEnable: "請填寫租戶名",
              remark: "請填寫備註",
              domain: "請填寫域名",
              onesignalKey: '請填寫APP通知金鑰',
          }
      }
  },
    // iPad管理
    iPadManage:{
        action: {},
        // 表单
        field: {
            id: "ID",
            index: "序號",
            machineCode: "機器編碼",
            name: "機器名",
            regId: "區域ID",
            regName: "區域名稱",
            seckey: "訪問密鑰",
            args: "其他參數",
            createBy: "創建者",
            createTime: "創建時間",
            updateBy: "更新者",
            updateTime: "更新時間",
            remark: "備注",
        },
        // 表单校验
        validate: {
            id: "請填寫ID",
            machineCode: "請填寫機器編碼",
            name: "請填寫機器名",
            regId: "請填寫區域ID",
            seckey: "請填寫訪問密鑰",
            args: "請填寫其他參數",
            remark: "請填寫備注",
        }
    },
    // 通知管理
    noticeManage:{
        noticeName:{
            0: "郵件",
            1: "電話"
        },
        noticeType: {
            0: "告警",
            1: "提醒",
            2: "公告",
            3: "超時",
        },
        field: {
            id: "ID",
            index: "序號",
            toFrom: "賬號",
            type: "賬號類型",
            noticeType: "通知類型",
            status: "是否啓用",

            createBy: "創建者",
            createTime: "創建時間",
            updateBy: "更新者",
            updateTime: "更新時間",
            remark: "備注",
        },
        validate: {
            id: "請填寫ID",
            type: "請選擇通知類型",
            toFrom: "請填寫賬號",
            noticeType: "請選擇通知類型",
            status: "請選擇是否啓用",
            remark: "請填寫備注",
        }
    },
    publicityManage:{
        publicityType: {
            0: '服務條款',
            1: '關於我們',
            2: '公告',
            3: '通知',
            4: '提醒',
        },
        field: {
            id: 'ID',
            index: '序號',
            type: '通告類型',
            status: '是否啟用',
            title: '通告標題',
            content: '通告内容',
            tenantKey: '租戶標識',

            createBy: '建立者',
            createTime: '創建時間',
            updateBy: '更新者',
            updateTime: '更新時間',
            remark: '備註',
        },
        validate: {
            id: '請填寫ID',
            type: '請選擇通告類型',
            status: '請選擇是否啟用',
            title: '請填寫通告標題',
            content: '請填寫通告內容',
            remark: '請填寫備註',
        }
    },
    statisticsManage: {
        table: {
            index: '序號',
            identifier: '電樁編號',
            useNum: '使用次數',
            chargeCapacity: '總用電量(kw)',

            startTime: '開始時間',
            endTime: '結束時間'
        },
        validate: {
            identifier: '請輸入電樁編號',
            startTime: '請選擇開始時間',
            endTime: '請選擇結束時間'
        }
    },
    serviceManage: {
        type: {
            0: '飲料',
            1: '食物',
            2: '廁所',
            3: '購物',
        },
        table: {
            id: 'ID',
            type: '類型',
            status: '狀態',
            name: '名稱',
            lng: '經度',
            lat: '緯度',
            address: '位址',
            description: '描述',
            reId: '關聯區域ID'
        },
        validate: {
            type: '請選擇類型',
            status: '請選擇狀態',
            name: '請填寫名稱',
            lng: '請填寫經度',
            lat: '請填寫緯度',
            address: '請填寫位址',
            description: '請填寫描述',
            reId: '關聯區域'
        }
    },
    // 白牌商信息
    companyManage: {
        field: {
            id: '主鍵',
            tId: '關聯租戶ID',
            name: '白牌商名商',
            attn: '白牌商聯絡人',
            tel: '白牌商電話',
            address: '白牌商地址',
            email: '白牌商電郵',
            invoiceDate: '發票日期',
            invoiceNo: '發票編號',
            contactPerson: '联系人',
            contactTel: '联系电话',
            contactEmail: '联系邮箱',
            monthlyFee: '系統接入月費預設值',
            company: '公司資訊'
        },
        validate: {
            id: '請輸入主鍵',
            tId: '請輸入關聯租戶ID',
            name: '請輸入白牌商名稱',
            attn: '請輸入白牌商聯絡人',
            tel: '請輸入白牌商電話',
            address: '請輸入白牌商地址',
            email: '請輸入白牌商電郵',
            invoiceDate: '請輸入发票日期',
            invoiceNo: '請輸入发票编号',
            contactPerson: '請輸入联系人',
            contactTel: '請輸入联系电话',
            contactEmail: '請輸入联系邮箱',
            monthlyFee: '請輸入系统接入月费预设值'
        }
    },
    tenantInfo: {
        table: {
            id: 'ID',
            pid: '父租戶ID',
            tenantKey: '租戶標識',
            name: '租戶名稱',
            domain: '綁定域名',
            tpId: '關聯套餐',
            authId: '關聯的權限',
            level: '級別',
            contact: '連絡人',
            email: '聯繫郵箱',
            phone: '聯繫電話',
            startTime: '啟用時間',
            expireTime: '過期時間',
        },
        args:{
            yedPayExtendParams: 'YedPay參數',
            yedPayApiKey: 'YedPay ApiKey',
            yedPaySingKey: 'YedPay SingKey',

            mPayExtendParams: 'MPay參數',
            mPayPubKey: 'MPay公鑰',
            mPayPriKey: 'MPay私鑰',
        },
        validate: {
            id: '請輸入ID',
            pid: '請輸入父租戶ID',
            tenantKey: '請填寫租戶標識',
            name: '請填寫租戶名稱',
            domain: '請填寫綁定的域名',
            tpId: '請選擇關聯套餐',
            authId: '請選擇關聯的權限',
            contact: '請填寫連絡人',
            email: '請填寫聯繫郵箱',
            phone: '請填寫聯繫電話',
            startTime: '請選擇開始時間',
            expireTime: '請選擇過期時間',

            yedPayExtendParams: '請填寫YedPay參數',
            yedPayApiKey: '請填寫YedPay ApiKey',
            yedPaySingKey: '請填寫YedPay SingKey',
            mPayExtendParams: '請填寫MPay參數',
            mPayPubKey: '請填寫MPay公鑰',
            mPayPriKey: '請填寫MPay私鑰',
        }
    },
    tenantPackage: {
        table: {
            id: 'ID',
            packageNo: '套餐編號',
            name: '套餐名稱',
            createTenantMax: '可創建租戶數',
            createUserMax: '可創建用戶數',
            createAppMax: '可注册APP數',
            expireLong: '套餐有效時長',
            tenantKey: '租戶標識',
        },
        validate: {
            id: '請輸入ID',
            packageNo: '請輸入套餐編號',
            name: '請輸入套餐名稱',
            createTenantMax: '請輸入可創建租戶數',
            createUserMax: '請輸入可創建用戶數',
            createAppMax: '請輸入可注册APP數量',
            expireLong: '請選擇套餐有效時長',
            tenantKey: '請輸入租戶標識',
        }
    },
    card: {
        cardType: {
            0: '白名單',
            1: '黑名單',
            2: '系統卡',
            3: '用戶卡',
        },
        table: {
            cardId: 'ID',
            cardNo: '卡號',
            cardType: '類型',
            cardStatus: '是否啟用',
        },
        validate: {
            cardId: '请填写ID',
            cardNo: '請填寫卡號',
            cardType: '請選擇類型',
            cardStatus: '請選擇狀態',
        }
    },
    waller: {
        wType: {
            1: '餘額',
            2: '贈額',
            3: '積分'
        },
        table: {
            walletId: 'ID',
            account: '賬戶',
            type: '類型',
            status: '是否凍結',
            balance: '餘額',
        },
        validate: {
            account: '請填寫帳戶',
            type: '請選擇類型',
            status: '請選擇狀態',
        }
    }
}

export default zhTW