<template>
  <Modal
      id="UserEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="userForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('user.field.balance')" name="balance">
                    <InputNumber
                        disabled
                        v-model:value="form.balance"
                        :placeholder="$t('user.validate.required.balance')"
                        :step="0.01"
                        style="width: 100%"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('user.field.account')" name="account">
                    <Input
                        :disabled="actionType === 'edit'"
                        :placeholder="$t('user.validate.required.account')"
                        v-model:value="form.account"
                    >
                    </Input>
                  </FormItem>
                </Col>
                <!-- <Col span="10" v-if="actionType == 'add'">
                  <FormItem :label="$t('user.field.password')" name="password">
                    <InputPassword :placeholder="$t('user.validate.required.password')"
                                   v-model:value="form.password">
                    </InputPassword>
                </FormItem>
                </Col> -->
                <!-- <Col span="10">
                  <FormItem :label="$t('user.field.register_time')" name="registerTime">
                    <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.registerTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                </FormItem>
                </Col> -->
                <Col span="10">
                  <FormItem :label="$t('user.field.register_type')" name="registerType">
                    <Select
                        :disabled="actionType === 'edit'"
                        v-model:value="form.registerType"
                        :placeholder="$t('user.validate.required.register_type')"
                        :options="registerTypeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.phone')"
                      name="phone"
                      :rules="[{
                        required: registerType === 2,
                        message: $t('user.validate.required.phone'),
                        trigger: 'change',
                        }]"
                  >
                    <Input :placeholder="$t('user.validate.required.phone')"
                           v-model:value="form.phone">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.email')"
                      name="email"
                      :rules="[{
                        required: registerType === 1,
                        message: $t('user.validate.required.email'),
                        trigger: 'change',
                        }]"
                  >
                    <Input :placeholder="$t('user.validate.required.email')"
                           v-model:value="form.email">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('user.field.chargingCard')" name="chargingCard">
                    <Select
                        v-model:value="form.chargingCard"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        style="width: 100%"
                        :options="cardList"
                        :filter-option="filterOption"
                        @change="handleChange"
                        allowClear
                    ></Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.chargingType')"
                      name="chargingType"
                      :rules="[{
                        required: requiredCard,
                        message: $t('user.validate.required.chargingType'),
                        trigger: 'change',
                        type: 'number',
                        }]"
                  >
                    <Select
                        v-model:value="form.chargingType"
                        :placeholder="$t('user.validate.required.chargingType')"
                        :options="typeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.chargingDefDuration')"
                      name="chargingDefDuration"
                      :rules="[{
                        required: requiredCard,
                        message: $t('user.validate.required.chargingDefDuration'),
                        trigger: 'change',
                        type: 'number',
                        }]"
                  >
                    <InputNumber style="width: 100%" :placeholder="$t('user.validate.required.chargingDefDuration')" v-model:value="form.chargingDefDuration"></InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.chargingDefPower')"
                      name="chargingDefPower"
                      :rules="[{
                        required: requiredCard,
                        message: $t('user.validate.required.chargingDefPower'),
                        trigger: 'change',
                        type: 'number',
                        }]"
                  >
                    <InputNumber style="width: 100%" :placeholder="$t('user.validate.required.chargingDefPower')" v-model:value="form.chargingDefPower"></InputNumber>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'"></Col>
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        :placeholder="$t('public.name.remark')"
                        v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "UserEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
  Textarea
} from "ant-design-vue";
import {UserAdd, UserEdit} from "@/api/user";
import {reactive, ref, toRefs, watch} from "vue";
import {useI18n} from "vue-i18n";
import {CardGetSelectList} from "@/api/card";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let userForm = ref()

let visible = ref(false)
let title = ref("")
let actionType = ref("")
let cardList = ref([])
let requiredCard = ref(false)
let registerType = ref()

const typeList = [{value: 1,label: i18n.t("public.chargeType.0")},{value: 2,label: i18n.t("public.chargeType.1")}]
const registerTypeList = [{value: 1, label: i18n.t("public.registerType.0")}, {value: 2, label: i18n.t("public.registerType.1")}]
const validateRules = ref({
  idUse: [
    {
      required: true,
      message: i18n.t("user.validate.required.id_use"),
      trigger: "change",
      type: "number",
    },
  ],
  balance: [
    {
      required: true,
      message: i18n.t("user.validate.required.balance"),
      trigger: "change",
      type: "number",
    },
  ],
  account: [
    {
      required: true,
      message: i18n.t("user.validate.required.account"),
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("user.validate.required.password"),
      trigger: "change",
    },
  ],
  registerTime: [
    {
      required: true,
      message: i18n.t("user.validate.required.register_time"),
      trigger: "change",
    },
  ],
  // email: [
  //   {
  //     required: registerType.value === 1,
  //     message: i18n.t("user.validate.required.email"),
  //     trigger: "change",
  //     type: "email",
  //   },
  // ],
  token: [
    {
      required: true,
      message: i18n.t("user.validate.required.token"),
      trigger: "change",
    },
  ],
  salt: [
    {
      required: true,
      message: i18n.t("user.validate.required.salt"),
      trigger: "change",
    },
  ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("user.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
  // phone: [
  //   {
  //     required: registerType.value === 2,
  //     message: i18n.t("user.validate.required.phone"),
  //     trigger: "change",
  //   },
  // ],
  registerType: [
    {
      required: true,
      message: i18n.t("user.validate.required.register_type"),
      trigger: "change",
      type: "number",
    },
  ],
  // chargingCard: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingCard"),
  //     trigger: "change",
  //   },
  // ],
  // chargingType: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingType"),
  //     trigger: "change",
  //   },
  // ],
  // chargingDefDuration: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingDefDuration"),
  //     trigger: "change",
  //     type: "number",
  //   },
  // ],
  // chargingDefPower: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingDefPower"),
  //     trigger: "change",
  //     type: "number",
  //   },
  // ],
})

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

async function addShow() {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  await getCardList();
  visible.value = true;
}

watch(() => form.value.chargingCard, (newValue, oldValue) => {
  // console.log('Value changed:', newValue, oldValue);
  requiredCard.value = newValue !== null && newValue !== undefined
});

watch(() => form.value.registerType, (newValue, oldValue) => {
  console.log('Value changed:', newValue, oldValue);
  registerType.value = newValue
});

async function editShow(obj) {
  title.value = i18n.t("public.name.edit");
  _resetData();
  await getCardList();
  actionType.value = "edit";
  form.value = JSON.parse(JSON.stringify(obj));
  form.value.chargingCard = form.value.chargingCard !== '' ? form.value.chargingCard : undefined
  registerType.value = form.value.registerType
  visible.value = true;
}

function submit() {
  userForm.value?.validate()
      .then(() => {
        let api;
        switch (actionType.value) {
          case "add":
            api = new UserAdd();
            break;
          case "edit":
            api = new UserEdit();
            break;
          default:
            return false;
        }
        api.post(form.value).then(response => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        });
      }).catch(() => {});
}

function _resetData() {
  form.value = {};
}

async function getCardList() {
  // 获取卡号列表
  const api = new CardGetSelectList()
  api.get().then(response => {
    if (0 === parseInt(response.data.code)) {
      let lt = []
      const ls = response.data.data
      ls.forEach(item => {
        lt.push({
          label: item.cardNo,
          value: item.cardNo,
        })
      })
      cardList.value = lt
    } else {
      cardList.value = []
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

function handleChange() {}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}


defineExpose({
  addShow,
  editShow
})
</script>

<style lang="less">
</style>
