import {Axios, Base} from "@/api/Base"

const api = "user"

export class UserAdd extends Base {
    path = api + "/add"
}

export class UserDelete extends Base {
    path = api + "/delete"
}

export class UserList extends Base {
    path = api + "/list"
}

export class UserEdit extends Base {
    path = api + "/edit"
}

export class UserListAll extends Base {
    path = api + "/listAll"
}

export class UserGetSelectList extends Base {
    path = api + "/getSelectList"
}

// 重置密码
export class UserResetPassword extends Base {
    path = api + "/resetPassword"
}

// 导出用户
export class UserExportToExcel extends Base {
    path = api + "/exportToExcel"
}

// 下载模板
export class UserDownloadUserTemp extends Base {
    path = api + "/downloadUserTemp"
}


// 导入
export class UserImport extends Base {
    path = api
    upload(param){
        return Axios.post(api + "/importUser", param, {
            headers: {
                "Content-Type":"multipart/form-data"
            }
        })
    }
}
